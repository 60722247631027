import { TokenVariant } from "../../shared/enum";
import { ModalBoxProps } from "../../shared/models";

export interface WhitelistedWallet {
  id: number;
  name: string;
  network_id: TokenVariant | "";
  address: string;
  location: InstitutionTypeAnswers;
  institution_name: string;
  institution_country: string;
  unhosted_description: string;
}

export interface WhitelistedWalletError {
  detail: {
    id: number;
    name: string;
    network_id: string;
    address: string;
    location: string;
    institution_name: string;
    institution_country: string;
    unhosted_description: string;
  };
}

export interface ModalAddWalletProps
  extends Pick<ModalBoxProps, "visible" | "close"> {
  setShowTransactionInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setTransactionText: React.Dispatch<React.SetStateAction<JSX.Element>>;
  initialData?: WhitelistedWallet;
}

export enum InstitutionTypeAnswers {
  INSTITUTION = "institution",
  PRIVATE = "private",
}

export interface AddWalletValuesProps {
  institution_type: InstitutionTypeAnswers;
  institution_country: string | null;
  institution_name: string | null;
  institution_other: string | null;
  unhosted_description: string | null;
  network_id: TokenVariant | "";
  name: string;
  wallet_address: string;
}

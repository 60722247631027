export enum UploadStatus {
  LOADING,
  NONE_FOUND,
  FOUND,
  IDLE,
  REMOVE,
  EXPIRED,
  INCORRECT,
}

export enum TokenVariant {
  btc = "BTC",
  eth = "ETH",
  usdc = "USDC",
  btc_t = "BTC_T",
  eth_tg = "ETH_TG",
  usdc_tg = "USDC_TG",
}

export enum ErrorsMessage {
  low = "TOO_LOW_TRADE_QUANTITY",
  insuffucient = "INSUFFICIENT_BALANCE",
  incorrectInstalment = "INCORRECT_INSTALMENT_STATUS",
  incorrectLoan = "INCORRECT_LOAN_STATUS",
  lastEthWallet = "CANT_DELETE_LAST_ETH_WALLET",
  incorrectOwner = "INCORRECT_WALLET_OWNER",
  instalmentNotFound = "INSTALMENT_NOT_FOUND",
  loanOpen = "LOAN_ALREADY_OPEN",
  loanNotFound = "LOAN_NOT_FOUND",
  walletNotWhitelisted = "NO_WHITELISTED_ETH_WALLET",
  nonUniqueWallet = "NON_UNIQUE_WHITELISTED_WALLET",
  exceptionApi = "ANCHORAGE_API_EXCEPTION",
  quoteRejected = "REQUEST_A_QUOTE_REJECTED",
  deleteAssigned = "CANT_DELETE_ASSIGNED_WALLET",
  unsupportedCurrency = "UNSUPPORTED_CURRENCY",
}

export enum LandingVariant {
  main = "main",
  lma = "lma",
  crypto = "crypto",
}
